import React, { useState, useEffect } from 'react';
import { Box, Select } from '@chakra-ui/react';
import moment from 'moment';

const DatePicker = ({ initialDate, onDateChange, isDisable = false }) => {
  const currentYear = new Date().getFullYear();
  const buddhistCurrentYear = currentYear + 543 + 1; // Convert to Buddhist year
  const years = Array.from({ length: 100 }, (_, i) => buddhistCurrentYear - i);
  const months = [
    'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
    'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม',
  ];

  // States
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [days, setDays] = useState([]);

  // Debug logs
  useEffect(() => {
    console.log('Initial Date:', initialDate);
    console.log('Selected Year:', selectedYear);
    console.log('Selected Month:', selectedMonth);
    console.log('Selected Day:', selectedDay);
    console.log('Days:', days);
  }, [selectedYear, selectedMonth, selectedDay, days]);

  // Initialize values from `initialDate`
  useEffect(() => {
    //onDateChange('');
    console.log(`initialDate: ${initialDate}`)
    if (initialDate) {
      const [year, month, day] = (moment(initialDate).format('YYYY-MM-DD')).split('-').map(Number);
      setSelectedYear(year + 543); // Convert to Buddhist year
      setSelectedMonth(month);
      setSelectedDay(day);
    }
  }, [initialDate]);

  // Recalculate `days` when `selectedYear` or `selectedMonth` changes
  useEffect(() => {
    if (selectedYear && selectedMonth) {
      const daysInMonth = new Date(selectedYear - 543, selectedMonth, 0).getDate(); // Convert to Gregorian year
      setDays(Array.from({ length: daysInMonth }, (_, i) => i + 1));
    } else {
      setDays([]);
    }
  }, [selectedYear, selectedMonth]);

  // Handlers
  const handleDayChange = (event) => {
    const newDay = parseInt(event.target.value, 10);
    setSelectedDay(newDay);
    if (newDay && selectedMonth && selectedYear) {
      onDateChange(formatDate(newDay, selectedMonth, selectedYear - 543)); // Convert back to Gregorian year
    }
  };

  const handleMonthChange = (event) => {
    const newMonth = parseInt(event.target.value, 10);
    setSelectedMonth(newMonth);
    setSelectedDay(''); // Reset day to ensure valid selections
  };

  const handleYearChange = (event) => {
    const newYear = parseInt(event.target.value, 10);
    setSelectedYear(newYear);
    setSelectedDay(''); // Reset day to ensure valid selections
  };

  const formatDate = (day, month, year) => {
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  };

  return (
    <Box display="flex" justifyContent="space-between" width="300px">
      {/* Day Dropdown */}
      <Select
        isDisabled={isDisable || !selectedMonth || !selectedYear}
        placeholder="วัน"
        value={selectedDay || ''}
        onChange={handleDayChange}
        borderRadius="8px"
      >
        {days.map((day) => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </Select>

      {/* Month Dropdown */}
      <Select
        isDisabled={isDisable}
        placeholder="เดือน"
        value={selectedMonth || ''}
        onChange={handleMonthChange}
        borderRadius="8px"
      >
        {months.map((month, index) => (
          <option key={index + 1} value={index + 1}>
            {month}
          </option>
        ))}
      </Select>

      {/* Year Dropdown */}
      <Select
        isDisabled={isDisable}
        placeholder="ปี"
        value={selectedYear || ''}
        onChange={handleYearChange}
        borderRadius="8px"
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default DatePicker;


/*import React, { useState, useEffect } from 'react';
import { Box, Select } from '@chakra-ui/react';

const DatePicker = ({ initialDate, onDateChange, isDisable=false }) => {
  const currentYear = new Date().getFullYear();
  const buddhistCurrentYear = currentYear + 543; // Convert to Buddhist year
  const years = Array.from({ length: 100 }, (_, i) => buddhistCurrentYear - i);
  const months = [
    'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
    'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
  ];

  const [initialYear, initialMonth, initialDay] = initialDate
    ? initialDate.split('-').map(Number)
    : [null, null, null];
  const initialBuddhistYear = initialYear + 543;

  const [selectedDay, setSelectedDay] = useState(initialDay || null);
  const [selectedMonth, setSelectedMonth] = useState(initialMonth || null);
  const [selectedYear, setSelectedYear] = useState((initialYear)?initialBuddhistYear:null);
  const [days, setDays] = useState([]);

  useEffect(() => {
    const daysInMonth = new Date(selectedYear - 543, selectedMonth, 0).getDate(); // Convert back to Gregorian year
    setDays(Array.from({ length: daysInMonth }, (_, i) => i + 1));
    console.log(`selectedMonth: ${selectedMonth}`)
  }, [selectedMonth, selectedYear]);

  const handleDayChange = (event) => {
    const newDay = parseInt(event.target.value);
    setSelectedDay(newDay);
    if(newDay && selectedMonth && selectedYear){
        onDateChange(formatDate(newDay, selectedMonth, selectedYear - 543)); // Convert back to Gregorian year and format
    }
  };

  const handleMonthChange = (event) => {
    const newMonth = parseInt(event.target.value);
    setSelectedMonth(newMonth);
    if(selectedDay && newMonth && selectedYear){
        onDateChange(formatDate(selectedDay, newMonth, selectedYear - 543)); // Convert back to Gregorian year and format
    }
  };

  const handleYearChange = (event) => {
    const newYear = parseInt(event.target.value);
    setSelectedYear(newYear);
    if(selectedDay && selectedMonth && newYear){
        onDateChange(formatDate(selectedDay, selectedMonth, newYear - 543)); // Convert back to Gregorian year and format
    }
  };

  const formatDate = (day, month, year) => {
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  };

  return (
    <Box display="flex" justifyContent="space-between" width="300px">
      <Select isDisabled={isDisable} placeholder='วัน' textStyle='body1.default' minW={"40px"} value={selectedDay} onChange={handleDayChange} borderRadius="100px">
        {days.map((day) => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </Select>
      <Select isDisabled={isDisable} placeholder='เดือน' textStyle='body1.default' mx={1} minW={"130px"} value={selectedMonth} onChange={handleMonthChange} borderRadius="100px">
        {months.map((month, index) => (
          <option key={index + 1} value={index + 1}>
            {month}
          </option>
        ))}
      </Select>
      <Select isDisabled={isDisable} placeholder='ปี' textStyle='body1.default' minW={"90px"} value={selectedYear} onChange={handleYearChange} borderRadius="100px">
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default DatePicker;
*/