// ActivityList.js
import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Collapse,
    Flex,
    Icon,
    IconButton,
    Spinner,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useToast,
    useDisclosure
} from '@chakra-ui/react';
import {
    MdExpandMore,
    MdExpandLess,
    MdScore,
    MdArrowDownward,
    MdArrowUpward
} from 'react-icons/md';
import { HSeparator } from 'components/Separator/Separator';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { IoAddCircle, IoBookOutline, IoRemoveCircle, IoTrashBinOutline } from 'react-icons/io5';
import { ArrowDownIcon, ArrowUpIcon, EditIcon } from '@chakra-ui/icons';
import { FaArrowRight, FaEye, FaPencilAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { getParam } from 'util/common';
import { loadLessons } from 'util/lessonAPI';
import AddEditActivityModal from './modals/AddEditActivityModal';
import AddActivityGroupModal from './modals/AddActivityGroupModal';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import GradebookActivityModal from './modals/GradebookActivityModal';
import { useAuth } from 'auth/AuthProvider';
import Breadcrumbs from './widgets/Breadcrumbs';
import PublishToggleButton from './widgets/PublishToggleButton';
import GroupNumberSelector from './widgets/GroupNumberSelector';
import ViewToggleButton from './widgets/ViewToggleButton';

const ActivityList = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [openIndex, setOpenIndex] = useState(0);
    const borderColor = useColorModeValue("gray.300", "gray.600");
    const bgColor = useColorModeValue("white", "blue.600");
    const secondaryColor = useColorModeValue("gray.800", "white");
    const history = useHistory();
    const toast = useToast();
    const [isLoading, setLoading] = useState(false);
    const [subjectID, setSubjectID] = useState();
    const [lessonID, setLessonID] = useState();
    const [lessonName, setLessonName] = useState("");
    const [lessons, setLessons] = useState([]);
    const [viewType, setViewType] = useState("admin");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [activityGroupName, setActivityGroupName] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [selectedActivityGroupID, setSelectedActivityGroupID] = useState(null);
    const [selectedActivityGroupStatus, setSelectedActivityGroupStatus] = useState(null);
    const [activityModalOpen, setActivityModalOpen] = useState(false);
    const [currentActivity, setCurrentActivity] = useState(null);
    const [currentActivityGroupID, setCurrentActivityGroupID] = useState(null);
    const [currentActivityIndex, setCurrentActivityIndex] = useState(null);
    const { isOpen: isGradebookOpen, onOpen: onGradebookOpen, onClose: onGradebookClose } = useDisclosure();
    const [selectedACtivityGraoupName, setSelectedActivityGroupName] = useState("")
    const [gradebookData, setGradebookData] = useState([])
    const [classroomID, setClassroomID] = useState(searchParams.get('classroom_id') ?? "")
    const { user, role, isUserExist, login, logout } = useAuth();

    const [isAdminViewOnList, setIsAdminViewOnList] = useState({});

    const breadcumLinks = [
        { url: `/#/user/classroom?cid=${searchParams.get('classroom_id')}&cname=${searchParams.get('cname')}&joincode=${searchParams.get('joincode')}`, text: `${searchParams.get('cname')}` },
        { url: `/#/user/class-model-admin?classroom_id=${searchParams.get('classroom_id')}&cname=${searchParams.get('cname')}&joincode=${searchParams.get('joincode')}`, text: `รายชื่อบทเรียน` },
        { url: undefined, text: `${searchParams.get('lesson')}` }
    ];

    const adminBreadcumLinks = [
        { url: `/#/user/class-model-admin?classroom_id=${searchParams.get('classroom_id')}&cname=${searchParams.get('cname')}&joincode=${searchParams.get('joincode')}`, text: `รายชื่อบทเรียน` },
        { url: undefined, text: `${searchParams.get('lesson')}` }
    ];

    useEffect(() => {

        const lessonTitle = getParam('lesson');
        const view = getParam('view');
        const subjectid = getParam('subject_id');
        const lessonid = getParam('lesson_id');

        if (view === "student" && role !== "STUDENT") {
            setViewType("STUDENT");
        }
        else{
            setViewType(role)
        }
        setLessonName(lessonTitle);
        setSubjectID(subjectid);
        setLessonID(lessonid);
        loadAllLessons();
        setClassroomID(searchParams.get('classroom_id') ?? "")
    }, []);

    const loadAllLessons = async () => {
        const lessonID = getParam('lesson_id');
        setLoading(true);
        const lessons = await loadLessons(lessonID);
        setLoading(false);
        setLessons(lessons);
    }

    const toggleCollapse = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    async function fetchResult(classroom_id, ipst_activity_ids){

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/gradebook/list_activity_result`, {
            classroom_id, ipst_activity_ids
        }, { withCredentials: true });

        console.log(`data: ${response.data.result}`)
        setGradebookData(response.data.result)
        onGradebookOpen()
    }

    const handleSetStatusToggle = async (itemIndex) => {
        const currentStatus = lessons[itemIndex].status;
        const newLessonStatus = currentStatus === 'published' ? 'draft' : 'published';

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/activity_group/new_activity_group_insert`, {
                opt: 'edit',
                group_id: lessons[itemIndex].id,
                lesson_id: lessonID,
                group_name: lessons[itemIndex].group_name,
                status: newLessonStatus,
            });

            if (response.data && response.data.status === "success") {
                const updatedLessons = lessons.map((lesson, index) => {
                    if (index === itemIndex) {
                        return {
                            ...lesson,
                            status: newLessonStatus
                        };
                    }
                    return lesson;
                });

                setLessons(updatedLessons);

                const newStatus = newLessonStatus === 'published' ? 'เผยแพร่' : 'ไม่เผยแพร่';

                toast({
                    title: `สถานะของบทเรียนถูกเปลี่ยนเป็น "${newStatus}"`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Error!',
                    description: "Cannot update status!",
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: 'Error!',
                description: "Cannot connect to the server!",
                status: 'warning',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const toggleActivityStatus = async (itemIndex, activityIndex) => {
        const currentActivities = lessons[itemIndex].activities;
        const newActivityStatus = currentActivities[activityIndex].status === 'published' ? 'draft' : 'published';

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/activity/set_activity_status`, {
                ipst_activity_id: currentActivities[activityIndex].id,
                status: newActivityStatus,
            });

            if (response.data && response.data.status === "success") {
                const updatedLessons = lessons.map((lesson, index) => {
                    if (index === itemIndex) {
                        const updatedActivities = lesson.activities.map((activity, actIndex) => {
                            if (actIndex === activityIndex) {
                                return {
                                    ...activity,
                                    status: newActivityStatus
                                };
                            }
                            return activity;
                        });

                        return {
                            ...lesson,
                            activities: updatedActivities
                        };
                    }
                    return lesson;
                });

                setLessons(updatedLessons);

                const newStatus = newActivityStatus === 'published' ? 'เผยแพร่' : 'ไม่เผยแพร่';

                toast({
                    title: `สถานะของกิจกรรมถูกเปลี่ยนเป็น "${newStatus}"`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Error!',
                    description: "Cannot update status!",
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: 'Error!',
                description: "Cannot connect to the server!",
                status: 'warning',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const moveActivityUp = async (itemIndex, activityIndex) => {
        if (activityIndex > 0) {
            const updatedLessons = [...lessons];
            const currentActivity = updatedLessons[itemIndex].activities[activityIndex];
            const previousActivity = updatedLessons[itemIndex].activities[activityIndex - 1];

            try {

                const activity_id1 = currentActivity.id
                const activity_id2 = previousActivity.id
                const order1 = currentActivity.order_num
                const order2 = previousActivity.order_num
                console.log(`activity_id1: ${activity_id1}`)
                console.log(`activity_id2: ${activity_id2}`)
                console.log(`order1: ${order1}`)
                console.log(`order2: ${order2}`)

                const response = await axios.post(`${process.env.REACT_APP_API_URL}/activity_group/swap_activity_order`, {
                    activity_id1: currentActivity.id,
                    activity_id2: previousActivity.id,
                    order1: currentActivity.order_num,
                    order2: previousActivity.order_num,
                });

                if (response.data && response.data.status === "success") {
                    updatedLessons[itemIndex].activities[activityIndex] = previousActivity;
                    updatedLessons[itemIndex].activities[activityIndex - 1] = currentActivity;

                    setLessons(updatedLessons);

                    toast({
                        title: "สลับตำแหน่งเรียบร้อย!",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: "Error!",
                        description: "ไม่สามารถสลับตำแหน่งได้!",
                        status: "warning",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: "Error!",
                    description: "Cannot connect to the server!",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    const moveActivityDown = async (itemIndex, activityIndex) => {
        if (activityIndex < lessons[itemIndex].activities.length - 1) {
            const updatedLessons = [...lessons];
            const currentActivity = updatedLessons[itemIndex].activities[activityIndex];
            const nextActivity = updatedLessons[itemIndex].activities[activityIndex + 1];

            try {
                const activity_id1 = currentActivity.id
                const activity_id2 = nextActivity.id
                const order1 = currentActivity.order_num
                const order2 = nextActivity.order_num
                console.log(`activity_id1: ${activity_id1}`)
                console.log(`activity_id2: ${activity_id2}`)
                console.log(`order1: ${order1}`)
                console.log(`order2: ${order2}`)

                const response = await axios.post(`${process.env.REACT_APP_API_URL}/activity_group/swap_activity_order`, {
                    activity_id1: currentActivity.id,
                    activity_id2: nextActivity.id,
                    order1: currentActivity.order_num,
                    order2: nextActivity.order_num,
                });

                if (response.data && response.data.status === "success") {
                    updatedLessons[itemIndex].activities[activityIndex] = nextActivity;
                    updatedLessons[itemIndex].activities[activityIndex + 1] = currentActivity;

                    setLessons(updatedLessons);

                    toast({
                        title: "สลับตำแหน่งเรียบร้อย!",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: "Error!",
                        description: "ไม่สามารถสลับตำแหน่งได้!",
                        status: "warning",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: "Error!",
                    description: "Cannot connect to the server!",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    const handleSaveActivityGroup = () => {
        if (!activityGroupName) {
            toast({
                title: "กรุณากรอกชื่อชุดกิจกรรม",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        if (isEditing) {
            const updatedLessons = [...lessons];
            updatedLessons[editingIndex].group_name = activityGroupName;
            setLessons(updatedLessons);
        } else {
            const updatedLessons = [...lessons];
            updatedLessons.push({
                group_name: activityGroupName,
                status: 'draft',
                activities: []
            });
            setLessons(updatedLessons);
        }

        setActivityGroupName('');
        setIsEditing(false);
        setEditingIndex(null);
        onClose();
    };

    const handleEditActivityGroup = (index) => {
        setActivityGroupName(lessons[index].group_name);
        setIsEditing(true);
        setEditingIndex(index);
        onOpen();
    };

    const handleAddActivity = (groupID) => {
        setCurrentActivityGroupID(groupID);
        setCurrentActivity(null);
        setCurrentActivityIndex(null);
        setActivityModalOpen(true);
    };

    const handleEditActivity = (groupID, activity, activityIndex) => {
        setCurrentActivityGroupID(groupID);
        setCurrentActivity(activity);
        setCurrentActivityIndex(activityIndex);
        setActivityModalOpen(true);
    };

    const closeActivityModal = () => {
        setActivityModalOpen(false);
    };

    const deleteActivityConfirm = (activity_id, activity_name, group_id) => {
        Swal.fire({
            title: "ยืนยันลบ?",
            text: `คุณต้องการลบ ${activity_name} ใช่หรือไม่`,
            showCancelButton: true,
            denyButtonColor: "#6CB4EE",
            confirmButtonColor: "#ED0A3F",
            confirmButtonText: `ยืนยัน (ลบ)`,
            cancelButtonText: "ยกเลิก"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteActivity(activity_id, group_id);
            }
        })
    }

    const deleteActivity = async (activity_id, group_id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/activity_group/delete_activity`, { activity_id });
            if (response.data && response.data.status === "success") {
                setLessons(prevLessons => {
                    const updatedLessons = [...prevLessons];
                    const groupIndex = updatedLessons.findIndex(group => group.id === group_id);

                    if (groupIndex !== -1) {
                        updatedLessons[groupIndex].activities = updatedLessons[groupIndex].activities.filter(activity => activity.id !== activity_id);
                    }

                    return updatedLessons;
                });

                toast({
                    title: "ลบเรียบร้อย!",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Error!",
                    description: "ไม่สามารถลบได้!",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Error!",
                description: "Cannot connect to the server!",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
        }
    }

    const deleteActivityGroupConfirm = (group_id, group_name) => {
        Swal.fire({
            title: "ยืนยันลบ?",
            text: `คุณต้องการลบ ${group_name} ใช่หรือไม่`,
            showCancelButton: true,
            denyButtonColor: "#6CB4EE",
            confirmButtonColor: "#ED0A3F",
            confirmButtonText: `ยืนยัน (ลบ)`,
            cancelButtonText: "ยกเลิก"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteActivityGroup(group_id);
            }
        })
    }

    const deleteActivityGroup = async (group_id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/activity_group/delete_activity_group`, { group_id });
            if (response.data && response.data.status === "success") {
                setLessons(prevLessons => prevLessons.filter(group => group.id !== group_id));

                toast({
                    title: "ลบเรียบร้อย!",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Error!",
                    description: "ไม่สามารถลบได้!",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Error!",
                description: "Cannot connect to the server!",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
        }
    }

    const listStudentScore = async (activityIDs) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/activity_group/delete_activity_group`, { group_id });
            if (response.data && response.data.status === "success") {
                setLessons(prevLessons => prevLessons.filter(group => group.id !== group_id));

                toast({
                    title: "ลบเรียบร้อย!",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Error!",
                    description: "ไม่สามารถลบได้!",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Error!",
                description: "Cannot connect to the server!",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
        }
    }

    const isStaff = (role) => {
        if(role.toLowerCase().includes("author") || role.toLowerCase().includes("editor")){
            return true;
        }
        return false;
    }

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '100px' }}>
            <Breadcrumbs links={(role === "TEACHER" || role === "STUDENT")?breadcumLinks:adminBreadcumLinks} />
            <Flex w="100%">
                <Flex w="100%" direction="column" mr={5}>
                    <Card>
                        <CardHeader>
                            <Flex justify="space-between">
                                <Text fontSize={20} fontWeight={500}>{lessonName}</Text>
                                {role !== "STUDENT" && viewType !== "STUDENT" && <Button leftIcon={<IoAddCircle />} colorScheme="blue" variant="outline" onClick={() => {
                                    setIsEditing(false);
                                    onOpen();
                                }}>
                                    เพิ่มชุดกิจกรรม
                                </Button>}
                            </Flex>
                            <HSeparator mt="5px" mb="5px" />
                        </CardHeader>
                        <CardBody>
                            {!isLoading && lessons.map((item, index) => (
                                <Box key={index} mb={1} borderRadius="lg" overflow="hidden">
                                    <Flex
                                        bg={openIndex === index ? 'blue.600' : 'blue.600'}
                                        color="white"
                                        fontWeight="bold"
                                        p={0}
                                        borderTopLeftRadius="5px"
                                        borderTopRightRadius="5px"
                                        cursor="pointer"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Flex align="center" h="50px" w="80%" onClick={() => toggleCollapse(index)}>
                                            <Text ml={5} fontWeight={400} fontSize={14}>
                                                {item.group_name}
                                            </Text>
                                        </Flex>
                                        {role !== "STUDENT" && viewType !== "STUDENT" && <Flex w={!isStaff(role)?"40%":"20%"} h="50px" justifyContent="center" alignItems="center" overflow="hidden">
                                            <Flex justify="center" align="center" w="150px" h="100%">
                                                <ViewToggleButton defaultColor={"white"} index={index} handleToggleIndex={(index, isAdminViewOn)=>{
                                                    setIsAdminViewOnList((prev) => ({
                                                        ...prev,
                                                        [index]: isAdminViewOn,
                                                    }));
                                                }} />
                                            </Flex>
                                            <Flex justify="center" align="center" w="150px" h="100%">
                                                <PublishToggleButton defaultColor={"white"} initialPublished={item.status === 'published' ? true: false} index={index} handleToggleIndex={handleSetStatusToggle} />
                                            </Flex>
                                            <Flex onClick={() => toggleCollapse(index)} align="center" justify="center" px={2}>
                                                <Icon as={openIndex === index ? MdExpandLess : MdExpandMore} fontSize="20px" />
                                            </Flex>
                                        </Flex>}
                                    </Flex>
                                    <Collapse in={openIndex === index} style={{ width: '100%' }}>
                                        <Box bg={bgColor} p={4}>
                                            <Table mb="10px" overflowX={{ sm: "scroll", lg: "hidden" }}>
                                                <Thead>
                                                    <Tr>
                                                        <Th maxW={"200px"} textAlign="left" borderColor={borderColor} color="gray.400" fontSize="sm" fontWeight="normal">
                                                            <Text className='default'>ชื่อกิจกรรม</Text>
                                                        </Th>
                                                        {isAdminViewOnList[index] != false && role !== "STUDENT" && classroomID && viewType !== "STUDENT" && <Th textAlign="center" borderColor={borderColor} color="gray.400" fontSize="sm" fontWeight="normal">
                                                            <Text className='default'>กลุ่ม</Text>
                                                        </Th>}
                                                        {isAdminViewOnList[index] != false && role !== "STUDENT" && viewType !== "STUDENT" && <Th textAlign="center" borderColor={borderColor} color="gray.400" fontSize="sm" fontWeight="normal">
                                                            <Text className='default'>สถานะ</Text>
                                                        </Th>}
                                                        {isAdminViewOnList[index] != false && <Th textAlign="center" borderColor={borderColor} color="gray.400" fontSize="sm" fontWeight="normal">
                                                            <Text className='default'>คะแนน</Text>
                                                        </Th>}
                                                        {isAdminViewOnList[index] != false && role !== "STUDENT" && viewType !== "STUDENT" && <Th textAlign="center" borderColor={borderColor} color="gray.400" fontSize="sm" fontWeight="normal">
                                                            <Text className='default'>ลบ</Text>
                                                        </Th>}
                                                        {isAdminViewOnList[index] != false && role !== "STUDENT" && viewType !== "STUDENT" && <Th textAlign="center" borderColor={borderColor} color="gray.400" fontSize="sm" fontWeight="normal">
                                                            <Text className='default'>แก้ไข</Text>
                                                        </Th>}
                                                        {isAdminViewOnList[index] != false && role !== "STUDENT" && viewType !== "STUDENT" && <Th textAlign="center" borderColor={borderColor} color="gray.400" fontSize="sm" fontWeight="normal">
                                                            <Text className='default'>ลำดับ</Text>
                                                        </Th>}
                                                        {/*role !== "STUDENT" && viewType !== "STUDENT" && <Th textAlign="center" borderColor={borderColor} color="gray.400" fontSize="sm" fontWeight="normal">
                                                            <Text className='default'>มุมมอง</Text>
                                                            <Text className='default'>นักเรียน</Text>
                                                        </Th>*/}
                                                        <Th textAlign="center" borderColor={borderColor} color="gray.400" fontSize="sm" fontWeight="normal">
                                                            ดู
                                                        </Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {item.activities.map((activity, activityIndex) => (
                                                        (isAdminViewOnList[index] !== false || activity.status == "published") && <Tr key={activityIndex}>
                                                            <Td textAlign="left" borderColor={borderColor} ps="0px" maxW={"200px"}>
                                                                <Text color={secondaryColor} fontWeight="normal" fontSize="md">
                                                                    {activity.activity}
                                                                </Text>
                                                            </Td>
                                                            {isAdminViewOnList[index] != false && role !== "STUDENT" && classroomID && viewType !== "STUDENT" && <Td textAlign="center" w={"150px"} borderColor={borderColor}>
                                                                <GroupNumberSelector activityID={activity.id} />
                                                            </Td>}
                                                            {isAdminViewOnList[index] != false && role !== "STUDENT" && viewType !== "STUDENT" && <Td textAlign="center" w={"150px"} borderColor={borderColor}>
                                                                <PublishToggleButton initialPublished={activity.status === 'published' ? true: false} defaultColor={"gray.600"} index={index} activityIndex={activityIndex} toggleActivityStatus={toggleActivityStatus} />
                                                            </Td>}
                                                            {isAdminViewOnList[index] != false && <Td textAlign="center" borderColor={borderColor} w="25px" boxSizing="border-box" p={1}>
                                                                <IconButton size="sm" color="blue" mr={1} icon={<MdScore />} onClick={()=>{
                                                                    const view = (role === "STUDENT") ? "&view=student" : "";
                                                                    const cid = searchParams.get('classroom_id') ?? ""
                                                                    history.push(`activity-score?subject_id=${subjectID}&lesson_id=${lessonID}&lesson=${lessonName}&activity_id=${activity.id}&activity=${activity.activity}&group_name=${item.group_name}${view}&classroom_id=${cid}`);
                                                                }} />
                                                            </Td>}
                                                            {isAdminViewOnList[index] != false && role !== "STUDENT" && viewType !== "STUDENT" && <Td textAlign="center" borderColor={borderColor} w="25px" boxSizing="border-box" p={1}>
                                                                <IconButton size="sm" color="red" mr={1} icon={<IoTrashBinOutline />} onClick={() => deleteActivityConfirm(activity.id, activity.activity, item.id)} />
                                                            </Td>}
                                                            {isAdminViewOnList[index] != false && role !== "STUDENT" && viewType !== "STUDENT" && <Td textAlign="center" borderColor={borderColor} w="25px" p={1}>
                                                                <IconButton size="sm" color="brand" mr={1} icon={<EditIcon />} onClick={() => handleEditActivity(item.id, activity, activityIndex)} />
                                                            </Td>}
                                                            {isAdminViewOnList[index] != false && role !== "STUDENT" && viewType !== "STUDENT" && <Td textAlign="center" borderColor={borderColor} w="25px" p={1}>
                                                                <IconButton disabled={activityIndex==item.activities.length-1?true:false} size="sm" color="brand" mr={1} icon={<MdArrowDownward />} onClick={() => moveActivityDown(index, activityIndex)} />
                                                                <IconButton disabled={activityIndex==0?true:false} size="sm" color="brand" mr={1} icon={<MdArrowUpward />} onClick={() => moveActivityUp(index, activityIndex)} />
                                                            </Td>}
                                                            {/*role !== "STUDENT" && viewType !== "STUDENT" && <Td textAlign="center" borderColor={borderColor} w="25px" p={1}>
                                                                <IconButton 
                                                                    size="sm"
                                                                    onClick={() => { 
                                                                        const view = "&view=student";
                                                                        const cid = searchParams.get('classroom_id') ?? ""
                                                                        history.push(`media-list?subject_id=${subjectID}&lesson_id=${lessonID}&lesson=${lessonName}&activity_id=${activity.id}&activity=${activity.activity}&group_name=${item.group_name}${view}&classroom_id=${cid}&cname=${searchParams.get('cname')}`);
                                                                    }}
                                                                    color={"blue"} icon={<FaEye />} />
                                                            </Td>*/}
                                                            <Td textAlign="center" borderColor={borderColor} w="25px" p={1}>
                                                                <IconButton 
                                                                    size="sm"
                                                                    onClick={() => { 
                                                                        const cid = searchParams.get('classroom_id') ?? ""

                                                                        if(isAdminViewOnList[index] != false){
                                                                            const view = (role === "STUDENT" || viewType === "STUDENT") ? "&view=student" : "";
                                                                            history.push(`media-list?subject_id=${subjectID}&lesson_id=${lessonID}&lesson=${lessonName}&activity_id=${activity.id}&activity=${activity.activity}&group_name=${item.group_name}${view}&classroom_id=${cid}&cname=${searchParams.get('cname')}&joincode=${searchParams.get('joincode')}`);
                                                                        }
                                                                        else{
                                                                            const view = "&view=student";
                                                                            history.push(`media-list?subject_id=${subjectID}&lesson_id=${lessonID}&lesson=${lessonName}&activity_id=${activity.id}&activity=${activity.activity}&group_name=${item.group_name}${view}&classroom_id=${cid}&cname=${searchParams.get('cname')}&joincode=${searchParams.get('joincode')}`);
                                                                        }
                                                                    }}
                                                                    color={"green"} icon={<FaEye />} />
                                                            </Td>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table>
                                            {isAdminViewOnList[index] !== false && <Flex pb={5} pr={1} justify="end" w="100%">
                                                {searchParams.get('classroom_id') && <Button mr={1} fontWeight={400} leftIcon={<IoBookOutline color="navy" />} variant="ghost" onClick={
                                                    ()=>{
                                                        const cid = searchParams.get('classroom_id') ?? ""
                                                        const activityIds = item.activities.map(activity => activity.id);
                                                        console.log(`cid: ${cid}`)
                                                        console.log(activityIds);

                                                        setActivityGroupName(item.group_name)
                                                        fetchResult(cid, activityIds)
                                                    }
                                                }>
                                                    gradebook
                                                </Button>}
                                                {viewType !== "STUDENT" && <Button mr={1} fontWeight={400} leftIcon={<IoAddCircle color="navy" />} variant="ghost" onClick={() => handleAddActivity(item.id)}>
                                                    เพิ่มกิจกรรม
                                                </Button>}
                                                {viewType !== "STUDENT" &&<Button mr={1} fontWeight={400} leftIcon={<IoRemoveCircle color="red" />} variant="ghost" onClick={() => deleteActivityGroupConfirm(item.id, item.group_name)}>
                                                    ลบชุดกิจกรรม
                                                </Button>}
                                                {viewType !== "STUDENT" &&<Button fontWeight={400} leftIcon={<FaPencilAlt color="teal" />} variant="ghost" onClick={() => {
                                                    setSelectedActivityGroupID(item.id);
                                                    setSelectedActivityGroupStatus(item.status);
                                                    handleEditActivityGroup(index);
                                                }}>
                                                    แก้ไขชื่อชุดกิจกรรม
                                                </Button>}
                                            </Flex>}
                                        </Box>
                                    </Collapse>
                                </Box>
                            ))}

                            {!isLoading && lessons.length === 0 && <Flex mt={10} justify="center"><Text>ไม่มีข้อมูล</Text></Flex>}
                            {isLoading && <Flex mt={10} justify="center"><Spinner mr={1} />กำลังดึงข้อมูล...</Flex>}
                        </CardBody>
                    </Card>
                </Flex>
            </Flex>

            <AddActivityGroupModal
                isOpen={isOpen}
                onClose={onClose}
                activityGroupStatus={selectedActivityGroupStatus}
                activityGroupID={selectedActivityGroupID}
                activityGroupName={activityGroupName}
                setActivityGroupName={setActivityGroupName}
                handleSaveActivity={handleSaveActivityGroup}
                editingIndex={editingIndex}
                setLessons={setLessons}
                lessonID={lessonID}
                isEditing={isEditing}
            />

            <AddEditActivityModal
                isOpen={activityModalOpen}
                onClose={closeActivityModal}
                activity={currentActivity}
                groupId={currentActivityGroupID}
                setActivity={setCurrentActivity}
                isEditing={!!currentActivity}
                activityIndex={currentActivityIndex}
                setLessons={setLessons}
            />

            <GradebookActivityModal isOpen={isGradebookOpen} onClose={onGradebookClose} data={gradebookData} activityGroupName={activityGroupName} />
        </Flex>
    );
};

export default ActivityList;
